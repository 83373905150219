<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.name.label' | translate: 'Name'"
        [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-date-input
        [label]="moduleFieldString + '.date.label' | translate: 'Date'"
        [placeholder]="'2022-11-22'"
        [name]="'date'"
        [showIcon]="true"
        [control]="formGroup?.controls?.date"
        [viewMode]="fieldViewMode"
      ></app-date-input>
    </div>
    <!-- <div class="col-12 md:col-6">
    <app-suggestion-input
      [label]="'Frequency'"
      [placeholder]="'Ex: 7 days'"
      [control]="formGroup?.controls?.frequency"
      [viewMode]="fieldViewMode"
      [items] = "[{label:'Yearly',value:365},{label:'Quarterly',value:90},{label:'Monthly',value:30},{label:'Weekly',value:7},{label:'Daily',value:1}]"
      [type]="'number'"
      [min]="0"
      [maxFractionDigits]="0"
      >
    </app-suggestion-input>
  </div> -->
    <div class="col-12">
      <app-drop-down-input
        [label]="moduleFieldString + '.complianceReportType.label' | translate: 'Compliance Report Type'"
        [placeholder]="
          moduleFieldString + '.complianceReportType.placeholder' | translate: 'Enter Compliance Report Type'
        "
        [name]="'dropdown'"
        [optionLabel]="'label'"
        [optionValue]="'value'"
        [items]="complianceReportTypesList"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.complianceReportType"
        [viewMode]="fieldViewMode == 'create' ? 'create' : 'view'"
        [badgeView]="true"
      ></app-drop-down-input>
    </div>
    @if (formGroup.controls.complianceReportType.value == complianceReportTypes.Policy) {
      <div class="col-12 md:col-12">
        <app-target-code-selector
          [label]="moduleFieldString + '.policy.label' | translate: 'Policy'"
          [placeholder]="moduleFieldString + '.policy.placeholder' | translate: 'Enter Policy'"
          [control]="formGroup?.controls?.policy"
          [viewMode]="fieldViewMode"
          [multi]="false"
          [optionLabel]="'name'"
          [optionValue]="'code'"
          [targetTypes]="['POLICY']"
          [extraFilters]="[{ property: 'policyStatus', operation: 'EQUAL', value: 'APPROVED' }]"
        >
        </app-target-code-selector>
      </div>
    }
    @if (formGroup.controls.complianceReportType.value == complianceReportTypes.Citation) {
      <div class="col-12 md:col-12">
        <app-target-code-selector
          [label]="moduleFieldString + '.citations.label' | translate: 'Citations'"
          [placeholder]="moduleFieldString + '.citations.placeholder' | translate: 'Enter Citations'"
          [control]="formGroup?.controls?.citations"
          [viewMode]="fieldViewMode"
          [multi]="true"
          [optionLabel]="'name'"
          [optionValue]="'code'"
          [targetTypes]="['CITATION']"
        >
        </app-target-code-selector>
      </div>
    }
    <div class="col-12 md:col-12">
      <app-text-editor
        [name]="'description'"
        [label]="moduleFieldString + '.description.label' | translate: 'Description'"
        [placeholder]="moduleFieldString + '.description.placeholder' | translate: 'Enter Description'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
        [advanced]="true"
      ></app-text-editor>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
